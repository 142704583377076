@media only screen and (min-width: 767px) {
    .header {
        background-color: #c89623;
        left: 0;
        z-index: 4;

        .profile_log {
            .user {
                flex-direction: row-reverse;

                .name {
                    color: white;
                    font-weight: 300;
                    font-family: 'Roboto';
                }

                .thumb {
                    margin-right: 0;
                }
            }

            .dropdown-menu {
                top: 46px;
            }
        }
    }

    .sidebar {
        background-color: white;
        top: 55px;
        width: 100%;
        height: auto;
        padding: 0;
        box-shadow: 0 5px 15px rgba(27, 25, 148, .08);

        .brand-logo {
            display: none;
        }

        .menu {
            border-top: 0;
            margin-top: 0;
            padding-top: 0;
            height: 100%;

            ul {
                li {
                    display: inline-block;

                    a {
                        color: #c89623;
                        margin-bottom: 0;
                        margin-right: 30px;
                        font-size: 15px;
                        font-weight: 400;
                        border-bottom: 2px solid transparent;

                        &.active {
                            border-color: #c89623;
                        }

                        &:hover,
                        &:focus,
                        &:active {
                            color: #8691b4 !important;
                            border-color: #8691b4;
                        }

                        .ikon {
                            font-size: 28px;
                        }
                    }
                }
            }
        }
    }

    .sidebar-footer {
        display: none;
    }

    .page_title {
        margin-top: 30px;
        margin-left: 0;

        .container {
            display: none;
        }
    }

    .content-body {
        margin-left: 0;
    }
}