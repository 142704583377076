@media only screen and (min-width: 768px) {
    .token-info {
        &.border-left {
            border-left: 1px solid #e6effb;
            border-top: 0;
        }
    }

    .token-buy {
        margin-top: 25px;
    }
}

@media only screen and (min-width: 576px) {
    .token-info {
        padding: 28px 0;
    }

    .token-balance {
        margin: 5px 0;

        &:not(:last-child) {
            margin-bottom: 25px;
        }

        &:not(.token-balance-with-icon) {
            margin-top: 1px;
            margin-bottom: 5px;
        }
    }
}

@media only screen and (min-width: 480px) {
    .ico-wrapper {
        .btn-xl {
            padding: 18px 30px;
        }
    }

    .token-balance-list {
        li {
            min-width: 25%;
        }
    }
}

@media only screen and (max-width: 991px) {
    .timeline-row {
        position: relative;
    }

    .timeline-row:before {
        position: absolute;
        top: 42px;
        left: 0;
        width: 100%;
        height: 0;
        content: "";
        border-bottom: 1px dashed rgba(186, 188, 195, 0.3);
    }

    .timeline-row:after {
        position: absolute;
        top: 42px;
        width: 15px;
        height: calc(100% + 1px);
        content: "";
        border: 1px dashed rgba(186, 188, 195, 0.3);
    }

    .timeline-row-done:after {
        position: absolute;
        top: 41px;
        width: 15px;
        height: calc(100% + 3px);
        content: "";
        border: 3px solid #16a1ff;
    }

    .timeline-row-done.timeline-row-odd:after {
        right: -15px;
    }

    .timeline-row-done.timeline-row-even:after {
        left: -15px;
    }

    .timeline-row-odd:after {
        right: -17px;
        border-left: 0;
        border-radius: 0 20px 20px 0;
    }

    .timeline-row-even:after {
        left: -17px;
        border-right: 0;
        border-radius: 20px 0 0 20px;
    }

    .timeline-row-even .timeline-item {
        padding-left: 40px;
        padding-right: 40px;
    }

    .timeline-row-even .timeline-item:before {
        right: 0;
        left: auto;
    }

    .timeline-row-even .timeline-item:after {
        left: 0;
        right: auto;
    }

    .timeline-row-last:after {
        display: none;
    }

    .timeline-row-last .timeline-item {
        padding-bottom: 0;
    }

    .timeline-item {
        padding: 30px 25px 24px;
    }

    .roadmap {
        .timeline-row {
            &::before {
                display: none;
            }

            .col-lg {
                margin-top: -20px;
            }
        }

        .timeline-title {
            &::before {
                border-top: 1px solid #CFA62C;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .order-xs-first {
        order: -1 !important;
    }

    .order-xs-last {
        order: 6 !important;
    }


    .buddy-balance {
        text-align: left;
    }

    .sidebar {
        background: #252a48;
    }

    // confirm alert
    .confirm-box {
        .confirm-alert-wrapper {
            margin-top: 25%;
        }
    }

    .phone_verified {
        display: block;

        >h5 {
            margin-right: 0;
            margin-bottom: 1.2rem;
        }

        .verify {
            margin-left: 1rem;
        }
    }

    td {
        .phone_verified {
            .verify {
                margin-left: 0;
            }
        }
    }

    .transaction-table {
        .table {
            min-width: 100%;

            tr {
                td {
                    &:nth-child(1) {
                        width: 50px;

                        .sold-thumb {
                            margin: 0;
                        }
                    }

                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4) {
                        display: none;
                    }

                    &:nth-child(5) {
                        width: 170px;
                    }

                    &:nth-child(6) {
                        width: 130px;
                    }

                    &:nth-child(7) {
                        width: 70px;
                        text-align: left !important;
                    }

                    &:nth-child(8) {
                        width: 40px;
                    }
                }
            }
        }
    }

    .kyc-table {
        .table {
            min-width: 100%;

            tr {

                td,
                th {
                    &:nth-child(1) {
                        width: 40px;
                    }

                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4) {
                        display: none;
                    }
                }
            }
        }
    }

    #___reactour {
        .reactour__helper {
            max-width: 340px;
        }
    }
}

@media only screen and (max-width: 576px) {
    .hidden-xs {
        display: none;
    }

    .signin-btn {
        .btn {
            padding: 10px;
            min-width: 110px;
        }
    }

    // confirm alert
    .confirm-box {
        .confirm-alert-wrapper {
            margin-top: 45%;
        }
    }

    .transaction-table {
        .table {
            tr {
                td {
                    &:nth-child(6) {
                        display: none;
                    }

                    &:nth-child(5) {
                        width: 150px;
                    }
                }
            }
        }
    }

    .buyer-seller {
        >.d-flex {
            flex-direction: row;
        }

        .table {
            tr {

                td,
                th {
                    &:nth-child(1) {
                        width: 200px;
                        min-width: 200px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 400px) {
    body {
        .styles_react-code-input__CRulA {
            >input {
                height: 47.5px !important;
                width: 49.5px !important;
            }
        }
    }
}