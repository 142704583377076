.btn-resend-otp {
    color: white;
    background-color: #c89623;
    border-radius: 7px;
    padding: 2px 9px;
    margin-left: 4px;
    transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;

    &:not(.btn):hover,
    &:not(.btn):focus,
    &:not(.btn):active {
        color: white !important;
        background-color: #c89623;
    }

    &.disabled {
        opacity: .5;
        cursor: not-allowed;
    }
}