#___reactour {
    .reactour__helper {
        --reactour-accent: #CB981E;
        padding-right: 40px;
        border-radius: 5px;
        max-width: 500px;
    
        .helper {
            --reactour-accent: #CB981E;
            line-height: 1.3;
            color: #2d2323;
        }

        > span {
            background: var(--reactour-accent);
        }
    }

    button,
    .reactour__close {
        min-width: auto;
    }
}

.JoTUc {
    position: absolute;
    top: 22px;
    right: 22px;
    width: 9px;
    height: 9px;
    color: #5e5e5e;
}

.evltZl {
    display: block;
    padding: 0;
    border: 0;
    background: none;
    font-size: 0;
    cursor: pointer;
}