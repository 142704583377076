.accordion-s2 .card {
    background-color: #fff;
    border: 2px solid rgba(34, 59, 85, .15);
    margin-bottom: 10px;
}

.accordion-s2 .card.active {
    border-color: rgba(254, 67, 80, .5);
}

.accordion-s2 .card-header a {
    color: #fe4350;
}

.accordion-s2 .card-header a.collapsed {
    color: #4c5a77;
}

.accordion-s2 .card-header a:hover {
    color: #fe4350;
}

.accordion-s2 .plus-minus .ti {
    color: #fe4350;
}

.accordion-s2 .collapsed .plus-minus .ti {
    color: rgba(34, 59, 85, .5);
}

.accordion-s2 .card {
    background-color: #fff;
    border: 2px solid rgba(34, 59, 85, 0.1);
    margin-bottom: 10px;
  }
  .accordion-s2 .card.active {
    border-color: rgba(22, 161, 255, 0.3);
  }
  .accordion-s2 .card-header {
    border: none;
    background: none;
    position: relative;
    padding: 0;
  }
  .accordion-s2 .card-header h5 {
    font-size: 1.2em;
  }
  .accordion-s2 .card p{
    color: #4c5a77;
  }
  .accordion-s2 .card-header a {
    color: #c89623;
    font-weight: 500;
    padding: 25px 30px 15px;
    display: block;
    margin-top: 0;
    cursor: pointer;
  }
  .accordion-s2 .card-header a.collapsed {
    padding: 25px 30px;
    color: #4c5a77;
  }
  .accordion-s2 .card-header a:hover {
    color: #c89623;
  }
  .accordion-s2 .card-body {
    padding: 0 30px 25px;
  }
  .accordion-s2 .plus-minus {
    cursor: pointer;
    display: block;
    position: absolute;
    right: 20px;
    top: 50%;
    height: 18px;
    width: 18px;
    margin-top: -9px;
    transition: all 0.5s ease;
  }
  .accordion-s2 .plus-minus .ti {
    position: relative;
    height: 100%;
    display: block;
    font-size: 18px;
    color: #c89623;
  }
  .accordion-s2 .collapsed .plus-minus {
    transform: rotate(180deg);
  }
  .accordion-s2 .collapsed .plus-minus .ti {
    color: rgba(34, 59, 85, 0.5);
  }