.card-token {
    background-image: linear-gradient(45deg, #141727 0%, #3a416f 100%);
    color: #fff;

    .card-body {
        background-color: transparent;
        padding: 33px;
    }

    .card-sub-title {
        margin-bottom: 4px;
    }
}

.btn-between {
    display: inline-flex;
    justify-content: space-between;

    .ti {
        margin-left: 20px;
        line-height: 24px;
    }
}

.btn-danger {
    color: #fff;
    background: #ff6868;
    border-color: #ff6868;
}

.ico-wrapper {
    .btn {
        position: relative;
        color: #fff;
        font-weight: 500;
        padding: 8px 20px;
        font-size: 13px;
        line-height: 24px;
        letter-spacing: 0.01em;
        border-radius: 5px;
        min-width: 160px;
        border: 1px solid;
        transition: all .4s ease;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        text-transform: none;
        font-weight: inherit;
        box-shadow: none;
    }

    .card {
        .card-header {
            border-bottom: 0;
            padding-bottom: 0;
        }

        &.buy-card {
            font-size: .9rem;
        }
    }

    .mb-3 {
        label {
            font-size: 14px;
            font-weight: 500;
            color: #6e81a9;
            line-height: 1.1;
            margin-bottom: 12px;
            display: inline-block;

            &.input-item-label {
                font-size: 14px;
                font-weight: 500;
                color: #495463;
                line-height: 1.1;
                margin-bottom: 12px;
                display: inline-block;
            }
        }
    }

    .btn {
        &.btn-icon {
            min-width: 0;
            position: relative;
            font-weight: 500;
            padding: 3px 12px 4px;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.01em;
            border-radius: 4px;
            min-width: 0;
            border: 1px solid;
            transition: all .4s ease;
            color: #495463;
            background: #e6effb;
            border-color: #e6effb;
        }
    }
}

.token-info {
    padding: 22px 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.border-left {
        border-left: 1px solid #e6effb;
    }

    .token-info-list {
        padding-bottom: 20px;

        li {
            color: #c89623;

            span {
                color: #495463;
                min-width: 110px;
                display: inline-block;
            }
        }
    }

    .token-info-icon {
        width: 70px;
        height: auto;
        margin-top: 2px;
        margin-bottom: 20px;
    }

    .token-info-head {
        color: #758698;
        margin-bottom: 0;
        font-size: 1.714em;
    }

    .token-info-sub {
        color: #758698;
        font-size: 1em;
        font-weight: 400;
    }
}

.token-balance-with-icon {
    display: flex;
    align-items: center;

    .token-balance-icon {
        flex-shrink: 0;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.2);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;

        img {
            width: 24px;
            line-height: 50px;
        }
    }
}

.token-balance {
    &:not(:last-child) {
        margin-bottom: 20px;
    }

    .lead {
        color: #fff;
        font-weight: 500;
        font-size: 1.5em;
        line-height: 1;
        letter-spacing: -0.02em;

        span {
            font-weight: 500;
            font-size: .6em;
            letter-spacing: 0.04em;
        }
    }
}

.token-balance-s2 {
    .lead {
        font-size: 1.2em;
    }

    .sub {
        display: block;
        line-height: .9;
        font-size: 11px;
    }
}

.card-sub-title {
    text-transform: uppercase;
    color: #c89623;
    letter-spacing: 0.1em;
    display: block;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    margin-bottom: 4px;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.token-balance-list {
    display: flex;

    li {
        min-width: 84px;
        min-width: 33.33%;
    }
}

.token-calc-wrapper {
    margin-bottom: 0 !important;
}

.token-calc {
    display: flex;
    padding-bottom: 10px;

    .token-pay-amount {
        position: relative;
        align-self: center;
        width: 180px;

        input {
            border-radius: 4px;
            border: 1px solid #d2dde9;
            width: 100%;
            padding: 10px 15px;
            line-height: 20px;
            font-size: .9em;
            color: rgba(73, 84, 99, 0.7);
            transition: all .4s;

            &.input-with-hint {
                padding-right: 75px;
            }

            &.has-error {
                border-color: rgba(255, 104, 104, 0.9);
            }
        }

        .token-pay-currency {
            position: absolute;
            right: 0;
            top: 9px;
            transform: translate(0);
            z-index: 1;
            padding: 0 10px;
            border-left: 1px solid #d2dde9;
        }

        .dropdown-content {
            position: absolute;
            top: calc(100% + 10px);
            left: 50%;
            transform: translateX(-50%);
            background: #fff;
            border-radius: 4px;
            z-index: 999;
            box-shadow: 0px 0 35px 0px rgb(0 0 0 / 20%);
        }
    }
}

.toggle-caret {
    position: relative;
    padding-right: 16px;
}

.toggle-tigger {
    cursor: pointer;
}

.link {
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    transition: all .2s;
}

.link-light {
    color: #758698;
}

.text-light {
    color: #758698 !important;
}

.toggle-caret {
    position: relative;
    padding-right: 16px;
}

.token-received {
    display: inline-flex;
    align-items: center;
}

.token-eq-sign {
    color: #758698;
    padding: 0 10px;
    font-size: 20px;
}

.token-amount {
    font-size: 16px;
    margin-bottom: 0;
    font-weight: bold;
}

.token-symbol {
    font-size: 12px;
}

.note {
    padding: 15px 15px 15px 45px;
    border-radius: 4px;
    position: relative;
    line-height: 1.4;
}

.note-plane {
    padding: 0 0 0 18px;
    background: transparent;
}

.note {
    [class*=fa] {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        font-size: 11px;
        width: 44px;
        text-align: center;
        line-height: inherit;
    }

    .note-text {
        font-size: .8rem;
    }

    p {
        font-size: 12px;
    }
}

.note-plane {
    [class*=fa] {
        top: 1px;
        line-height: 14px;
        font-size: 14px;
        transform: translateY(1px);
        width: 18px;
        text-align: left;
    }
}

.input-note {
    font-size: 12px;
    line-height: 1.5em;
    color: #758698;
    font-weight: 400;
    display: block;
    padding: 8px 0 0;
}

.token-buy {
    margin: 20px 0 5px;
}

.ico-tx-dt {

    .rdt_TableRow {
        background: #fff;
        margin-bottom: 0;
        border-radius: 4px;
        position: relative;

        &:last-of-type {
            .rdt_TableCell {
                border-bottom: none;
            }
        }

        .rdt_TableCell {
            padding: 20px 10px 20px 0;
            border-bottom: 1px solid #e6effb;

            &:last-of-type {
                text-align: right;
                display: inline-block;
            }
        }
    }

    .rdt_TableCol {
        padding-left: 0;
    }

    .rdt_Pagination {
        button {
            min-width: 0;
        }
    }

    .lead {
        font-size: 14px;
        font-weight: 700;
        color: #495463;
        letter-spacing: 0.02em;
        line-height: 1;
        display: block;
        margin-top: 0;
    }

    .sub {
        font-size: 12px;
        font-weight: 400;
        color: #758698;
        letter-spacing: 0.02em;
        line-height: 1;
        padding-top: 7px;
        display: block;
    }
}

.toggle-content,
.toggle-class {
    display: none;
}

.data-state {
    height: 30px;
    width: 30px;
    line-height: 28px;
    margin-right: 12px;
    flex-shrink: 0;

    &::after {
        display: block;
        font-family: 'themify';
        color: inherit;
        height: 100%;
        width: 100%;
        font-size: 14px;
        border-radius: 50%;
        text-align: center;
        border: 1px solid;
    }
}

.data-state-pending {
    &::after {
        content: '\e6c5';
        color: #ffc100;
        border-color: #ffc100;
    }
}

.data-state-success {
    &::after {
        content: '\e64c';
        color: #00d285;
        border-color: #00d285;
    }
}

.data-state-canceled {
    &::after {
        content: '\e64c';
        color: #00d285;
        border-color: #00d285;
    }
}

.data-state-progress {
    &::after {
        content: '\e6c6';
        color: #1babfe;
        border-color: #1babfe;
    }
}

.data-state-approved {
    &::after {
        content: '\e64c';
        color: #00d285;
        border-color: #00d285;
    }
}

.data-state-canceled {
    &::after {
        content: '\e646';
        color: #ff6868;
        border-color: #ff6868;
    }
}

.data-state-progress {
    &::after {
        content: '\e6c6';
        color: #1babfe;
        border-color: #1babfe;
    }
}

.align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important;
}

// form
.select-wrapper {
    position: relative;

    select {
        width: 100%;
        line-height: 20px;
        padding: 10px 20px 10px 15px;
        border: none;
        border-radius: 4px;
        height: 42px !important;
        font-size: 14px;
        color: #6e81a9;
        background: transparent;
        vertical-align: top;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &.input-bordered {
            border-radius: 4px;
            border: 1px solid #d2dde9;
            width: 100%;
            padding: 10px 15px;
            line-height: 20px;
            font-size: .9em;
            color: #6e81a9;
            transition: all .4s;

            &.has-error {
                border-color: rgba(255, 104, 104, 0.9);
            }
        }
    }

    &::after {
        position: absolute;
        height: 42px;
        width: 44px;
        line-height: 44px;
        text-align: center;
        top: 50%;
        right: 0;
        font-family: 'themify';
        transform: translateY(-50%);
        content: '\e64b';
        font-size: 12px;
    }
}

// modal / popup
.popup-modal {
    font-size: .9rem;

    .modal-header {
        padding: 1.5rem 1.5rem .8rem 1.5rem;
        border: none;

        button {
            &.btn-close {
                position: absolute;
                top: -16px;
                right: -16px;
                background-color: #fff;
                opacity: 1;
                transition: all .4s;
                box-shadow: 0px 0px 25px 0px rgb(0 0 0 / 30%);
                height: 38px;
                width: 38px;
                border-radius: 50%;
                padding: 0.3rem;
            }
        }
    }

    .modal-body {
        padding: 1.2rem 1.5rem;
    }

    .mb-3 {
        label {
            font-size: .9rem;
            font-weight: 500;
            color: #495463;
            line-height: 1.1;
            margin-bottom: 12px;
        }
    }

    .form-control {
        border-radius: 4px;
        border: 1px solid #d2dde9;
        width: 100%;
        padding: 10px 15px;
        line-height: 20px;
        font-size: .9em;
        color: rgba(73, 84, 99, 0.7);
        transition: all .4s;

        &.is-invalid,
        &:invalid {
            border-color: #ff788e;
        }
    }
}

.sap {
    width: 100%;
    border-bottom: 1px solid #e6effb;
}

.token-sales {
    .sap {
        margin: 1px 0 0;
    }
}

.progress-info {
    display: flex;
    justify-content: space-between;

    li {
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 500;
        color: #495463;
        line-height: 1.4;

        span {
            color: #758698;
            display: block;
            font-size: 12px;
        }
    }
}

.progress-bar {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #c89623;
    transition: width .6s ease;
}

.progress-bar {
    display: block;
    color: #758698;
    background: #aebac7;
    height: 6px;
    border-radius: 3px;
    margin: 12px 0 30px;
    position: relative;
}

.progress-hcap,
.progress-scap,
.progress-psale,
.progress-percent {
    position: absolute;
    top: 0;
    left: 0;
    height: 6px;
    border-radius: 3px;

    >div {
        position: absolute;
        right: 0;
        transform: translateX(50%);
        width: 120px;
        font-size: 10px;
        line-height: 14px;
        font-weight: 500;
        padding: 25px 0 0 0;
        text-transform: uppercase;

        span {
            display: block;
            color: #495463;
        }

        &::after {
            position: absolute;
            top: 0;
            left: 50%;
            margin-left: -2px;
            width: 2px;
            height: 20px;
            content: '';
            background: rgba(78, 92, 110, 0.3);
        }
    }
}

.progress-hcap {
    background: #d2dde9;
}

.progress-scap {
    background: #8299d3;
}

.progress-percent {
    background: #c89623;

    &::after {
        position: absolute;
        content: '';
        right: 0;
        top: 50%;
        height: 18px;
        width: 18px;
        border: 3px solid #c89623;
        background: #fff;
        border-radius: 50%;
        transform: translate(50%, -50%);
    }
}

.countdown-clock {
    display: flex;
    justify-content: space-between;
    margin: 0 -7px;

    >div {
        border-radius: 4px;
        border: 1px solid #e6effb;
        margin: 0 7px;
        flex-grow: 1;
        text-align: center;
        padding: 10px 0;
    }
}

.countdown-time {
    font-size: 24px;
    color: #495463;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.1em;
}

.countdown-text {
    display: block;
    font-weight: 500;
    font-size: 11px;
    color: #758698;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    line-height: 1;
}

.token-sales .countdown-clock:last-child,
.token-sale-graph .chart-tokensale:last-child {
    margin-bottom: 5px;
}

.token-sales-timeline {
    padding: 1px 0;

    .timeline-bar {
        font-size: 12px;
        font-weight: 400;
        color: #6e81a9;
        background: #e0e8f3;
        border-radius: 3px;
        height: 16px;
        width: 100%;
        margin: 20px 0;
        display: flex;
    }

    .timeline-base {
        width: 33.3333%;
        background: #c8d1dd;
        border-radius: 2px;
        height: 8px;
        margin: 4px 2px 4px 4px;
        position: relative;
    }

    .timeline-base-title {
        color: #758698;
        position: absolute;
        left: -4px;
        bottom: 100%;
        line-height: 1;
        margin-bottom: 10px;
        padding-left: 8px;

        &::before {
            content: ' ';
            background: #e0e8f3;
            width: 2px;
            height: 50px;
            position: absolute;
            left: 0;
            top: 0px;
        }

        &.end {
            left: auto;
            right: 0;

            &::before {
                display: none;
            }
        }
    }

    .timeline-base-stat {
        position: absolute;
        right: 46%;
        top: 100%;
        line-height: 1;
        margin-top: 10px;
    }

    .timeline-base-bar {
        position: relative;
        background: #c89623;
        border-radius: 2px;
        display: block;
        height: 8px;

        &.with-indicator {
            &::after {
                position: absolute;
                content: '';
                right: 0;
                top: 3.5px;
                height: 18px;
                width: 18px;
                border: 3px solid #c89623;
                background: #fff;
                border-radius: 50%;
                transform: translate(50%, -50%);

            }
        }
    }

    &.current-phase {
        padding-left: 10px;
        margin-top: 2.5rem;

        .timeline-bar {
            border-radius: 5px;
            height: 6px;
        }

        .timeline-base {
            border-radius: 5px;
            height: 6px;
            margin: 0;
        }

        .timeline-base-title {
            &:not(.end) {
                left: -16px;
            }

            span {
                color: #495463;
                display: block;
                font-size: 14px;
                font-weight: 500;
                text-transform: uppercase;
            }

            small {
                color: #758698;
                display: block;
                font-size: 12px;
                font-weight: 500;
                text-transform: uppercase;
                margin-bottom: 3px;
            }

            &::before {
                display: none;
            }
        }
    }

    .timeline-base-text {
        color: #758698;
        position: absolute;
        left: -34px;
        top: -38px;
        line-height: 1;
        margin-bottom: 10px;
        padding-left: 8px;

        &.start {
            left: -14px;
        }

        &.end {
            left: auto;
            right: -2px;
        }
    }

    &.roadmap {
        margin-top: .5rem;

        .timeline-base-title.end {
            &::after {
                content: ' ';
                background: #e0e8f3;
                width: 2px;
                height: 50px;
                position: absolute;
                right: -2px;
                top: 0px;
            }
        }
    }
}

.balance-overview {
    border-radius: 4px;
    padding: 0 15px;
    border: 2px solid #e6effb;
    overflow: hidden;
}

.balance {
    position: relative;
    padding: 15px 0;

    .sub {
        color: #c89623;
        font-size: 13.5px;
        display: block;
    }
}

.balance-overview-title {
    font-size: 12px;
    letter-spacing: 0.03em;
    margin-bottom: 0;
    text-transform: uppercase;
}

.balance-overview-value {
    font-size: 20px;
    line-height: 1.2;
    font-weight: 600;
    color: #2C3154;
    display: block;

    span {
        font-size: 0.7em;
        display: inline-block;
        margin-left: 5px;
    }
}

.deposit-details {
    .sub-amt {
        color: #758698;
        font-size: 14px;
        display: inline-block;
        margin-left: 10px;
    }
}

#ico-page-wrapper {
    font-family: "Poppins", sans-serif;
    background-color: #fff;
    color: #fff;
    font-size: 15px;
    line-height: 1.86;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ul,
    ol,
    table {
        margin: 0 0 18px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        line-height: 1.33;
        font-weight: 600;
        color: #373e45;
    }

    h1,
    .h1 {
        font-size: 2.93em;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    blockquote {
        color: #fff;
    }

    h5 {
        font-size: 1.125em;
    }

    p {
        font-size: 0.9em;

        &.lead {
            font-size: 0.9em;
            font-weight: 400;
        }
    }

    .btn {
        font-family: "Poppins", sans-serif;
        font-size: 12px;
        line-height: 46px;
        font-weight: 600;
        padding: 0 34px;
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        min-width: 140px;
        max-width: 100%;
        border: none;
        box-sizing: border-box;
        transition: all 0.5s;
        position: relative;
        z-index: 2;
        -ms-border-radius: 40px;
        border-radius: 40px;
        background-image: -webkit-linear-gradient(left, #16a3fe 0%, #41faa4 100%);
        background-image: linear-gradient(to right, #16a3fe 0%, #41faa4 100%);

        &.btn-outline {
            line-height: 42px;
            background: transparent;
            border: 2px solid rgba(255, 255, 255, .4);

            &:hover {
                color: #c89623;
                background: #fff;
                border-color: #fff;
            }
        }

        &.btn-icon {
            background: #fff;
            color: #c89623;
            padding-right: 50px;
            padding-left: 34px;

            [class*="fa-"] {
                position: absolute;
                line-height: 38px;
                width: 38px;
                text-align: center;
                border-radius: 50%;
                top: 4px;
                right: 4px;
                background: #c89623;
                color: #fff;
                transition: all 0.4s;
            }

            &.btn-invert {
                color: #fff;
                background-color: #c89623;
                border-color: #c89623;

                [class*="fa-"] {
                    background: #fff;
                    color: #c89623;
                }
            }

            &.btn-dark {
                color: #fff;
                background: linear-gradient(45deg, #3a416f 0%, #141727 100%);
                border-color: #141727;

                [class*="fa-"] {
                    background: #fff;
                    color: #141727;
                }
            }

            &:hover,
            &:focus {
                color: #c89623 !important;
            }
        }
    }

    .btns {
        li {
            display: inline-block;
            margin: 10px 15px;
        }
    }

    .navbar {
        .btn-outline {
            min-width: auto;
        }

        .navbar-nav {
            >li {
                >a {
                    background: transparent;
                    text-transform: uppercase;
                    color: inherit;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 38px;
                    margin: 0 8.5px;
                    transition: all .35s ease;

                    &:hover,
                    &:active {
                        color: #c89623 !important;
                        cursor: pointer;
                    }

                    &.nav-link.btn {
                        padding: 0 17px !important;
                        line-height: 34px;
                        margin: 0;
                    }
                }
            }
        }

        .navbar-btns {
            >li {
                display: inline-block;

                >a {
                    font-size: 12px;
                    font-weight: 600;
                }

                >span {
                    background: transparent;
                    text-transform: uppercase;
                    color: #fff;
                    font-size: 17px;
                    font-weight: 800;
                    line-height: 38px;
                    margin: 0 8.5px;
                    transition: all 0.35s ease;
                    display: block;
                    margin-top: 4px;

                    >span {
                        display: none;
                    }
                }
            }
        }
    }

    .site-header {
        position: relative;

        .navbar {
            border-radius: 0;
            border: 0 none;
            min-width: 300px;
            margin: 0;
            z-index: 9;
            padding: 0;
            transition: all .5s ease;
            border-bottom: 1px solid transparent;

            &.is-transparent {
                background: transparent;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
            }
        }

        &.has-fixed {
            .navbar {
                position: fixed;
                width: 100%;
                z-index: 9;
                top: 0;
                background: #fff;
                border-bottom-color: rgba(0, 0, 0, 0.04);
            }

            .menu-top {
                >li {
                    >a {
                        text-transform: capitalize;
                        color: #223b55;
                    }
                }
            }

            .btn-outline {
                color: #c89623;
                border-color: rgba(200, 150, 35, .2);

                &:hover {
                    background: #c89623;
                    color: #fff !important;
                }
            }

            .navbar-btns {
                >li {
                    >span {
                        text-transform: uppercase;
                        color: #223b55;
                    }
                }
            }
        }

        .navbar-nav {
            >li {
                >a {
                    text-transform: capitalize;
                    color: #fff;
                }
            }
        }

        .navbar-full {
            padding-left: 20px;
            padding-right: 20px;
        }

        .navbar-brand {
            height: auto;
            padding: 0;
            margin-top: 20px;
            margin-bottom: 20px;
            margin-left: 0;
            margin-right: 15px;
            z-index: 6;

            img {
                outline: 0;
                border: 0 none;
                height: auto;
                vertical-align: top;
                width: auto;
                max-width: 100%;
                display: block;

                &.logo-2 {
                    display: none;
                }
            }
        }

        .navbar-collapse {
            padding: 0;
            background-color: transparent;
            color: #3c3c3c;

            .navbar-nav {
                background-color: transparent;
            }

            ul+ul {
                margin-top: 0;
            }
        }

        .navbar-btns {
            margin-left: 20px;

            >li {
                &:hover {
                    >a {
                        color: #141727;
                        background: #fff;
                    }
                }
            }
        }

        .navbar-toggler {
            font-size: 1.25rem;
            line-height: 1;
            border: 1px solid transparent;
            padding: 0 5px;
            color: #fff;
            border-radius: 2px;
            border: none;
            transition: all .5s ease;
            background: linear-gradient(135deg, #3a416f 0%, #141727 100%);

            .ti {
                color: #c89623;
            }

            &:focus {
                box-shadow: none;
            }
        }
    }

    .navbar-toggler {
        padding: 0 5px;
        color: #fff;
        background: #fff;
        border-radius: 2px;
        border: none;
        transition: all .5s ease;
        background: linear-gradient(135deg, #3a416f 0%, #141727 100%);
        margin-top: 20px;
        margin-right: 15px;

        .ti {
            color: #c89623;
            line-height: 30px;
            font-size: 20px;
        }
    }

    .navbar-toggler-icon {
        display: inline-block;
        width: 1.5em;
        height: 1.5em;
        vertical-align: middle;
        content: "";
        background: no-repeat center center;
        background-size: 100% 100%;
        line-height: 30px;
    }

    .banner {
        &.banner-full {
            min-height: 100vh;
        }

        .banner-content {
            padding-top: 50px;
        }

        .row,
        .banner-content {
            height: 100%;
        }
    }

    .banner-1 {
        padding-top: 50px;
        background: linear-gradient(135deg, #3a416f 0%, #141727 100%);
        background-size: contain, contain;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    section {
        &.parallax {
            background-attachment: fixed;

            &.banner {
                &::before {
                    background-image: url(../images/dot-b.png);
                }
            }
        }
    }

    .particles-container {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 0;
    }

    .page-header {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        left: 50%;
        overflow: hidden;
        transform: translateX(-50%);
    }

    .menu-top {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    }

    .countdown-text {
        color: #a7a7a7;
        display: block;
        font-size: 12px;
        margin-top: 13px;
    }

    .presale-stage {
        background: url(../images/presale-bg.png) 0 0 no-repeat, #fff9f7;
        background-size: contain, 100%;
        box-shadow: 0px 15px 20px 0px rgb(0 0 0 / 10%);
        border-radius: 6px 6px 0 0;
        padding: 30px 30px 18px 30px;

        h5 {
            background: linear-gradient(0deg, #c89623 0%, #b6871a 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        h6 {
            font-weight: 400;
            margin-bottom: 6px;
            color: #223b55 !important;
        }

        p {
            position: relative;
            font-weight: 600;
            color: #c89623;
        }
    }

    section {
        position: relative;

        &::before {
            position: absolute;
            display: block;
            left: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
            content: '';
        }

        &.buy-tokens,
        &.token-details {
            &::before {
                height: auto;
            }

            a {
                &:hover {
                    color: white !important;
                    cursor: pointer;
                }
            }
        }
    }

    .section-head {
        padding-bottom: 65px;
    }

    .section-pad {
        padding-top: 120px;
        padding-bottom: 120px;
    }

    .section-title {
        color: #c89623;
        padding-bottom: 8px;
        font-weight: 600;
    }

    .section-bg-gradient {
        background: linear-gradient(45deg, #3a416f 0%, #141727 100%);
        color: #fff;
    }

    .section-bg {
        color: #4c5a77;
        background: #fff;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: #4c5a77;
        }

        p {
            color: #4c5a77;
        }
    }

    img {
        height: auto;
        max-width: 100%;
        vertical-align: top;
    }

    .token-amount {
        margin-bottom: 0;
        line-height: 1;
    }
}

.presale-home-wrapper {
    margin: 0;
    margin-top: -130px !important;
}

.presale-status {
    padding: 30px 30px;
    background: #171A2D;
    background: linear-gradient(45deg, #2B3154 0%, #222741 100%);
    border-radius: 0 0 6px 6px;
    box-shadow: 0px 15px 20px 0px rgb(0 0 0 / 10%);

    h5 {
        font-weight: 600;
    }
}

.presale-bar {
    padding: 6px;
    width: 100%;
    background: #fff;
    border-radius: 16px;

    .presale-bar-percent {
        height: 20px;
        border-radius: 20px;
        background: linear-gradient(45deg, #3a416f 0%, #141727 100%);
    }
}

.token-countdown {
    margin: 0 -20px;

    .col {
        display: inline-block;
        width: auto;
        padding: 0 20px;
    }
}

.countdown-digit {
    position: relative;
    font-weight: 600;
    // color: #c89623;
    letter-spacing: 22px;

    background: linear-gradient(45deg, #3a416f 0%, #141727 100%);
    border-radius: 20px;
    padding: 3px 15px;
    color: #fff;
}

.presale-numbers {
    padding-top: 6px;
    font-weight: 0.93em;
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.no-gutters,
.no-gutters {

    .col,
    >[class*=col-] {
        padding-right: 0;
        padding-left: 0;
    }
}

#buy-token {
    p {
        color: #4c5a77;
    }
}

.ui-shape {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    left: 50%;
    overflow: hidden;
    transform: translateX(-50%);

    &::before,
    &::after {
        position: absolute;
        content: '';
        height: 210px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        transition: all 0.4s;
    }

    &::before {
        top: 15%;
        left: -4%;
        width: 100px;
        background-image: url(../images/shape-a.png);
    }

    &::after {
        bottom: 10%;
        right: -4%;
        width: 90px;
        background-image: url(../images/shape-b.png);
    }
}

.howto-item {
    padding-bottom: 50px;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
}

.howto-icon {
    position: relative;
    width: 80px;
    transform: rotate(-45deg);
    margin-bottom: 25px;

    &::after {
        position: absolute;
        content: '';
        height: 62px;
        width: 62px;
        top: 50%;
        left: 50%;
        border: 2px solid rgba(254, 67, 80, .2);
        border-radius: 8px;
        transform: translate(-50%, -50%);
    }

    img {
        outline: 0;
        border: 0 none;
        max-width: 100%;
        height: auto;
        vertical-align: top;
        transform: rotate(45deg);
    }
}

#token-details {
    &::before {
        background: url(../images/dot-b.png) 100% 100% no-repeat;
        background-size: cover;
        background-repeat: repeat-y;
    }

    .section-head {
        padding-bottom: 30px;
    }

    .table {
        --bs-table-bg: rgba(0, 0, 0, 0);
        --bs-table-accent-bg: rgba(0, 0, 0, 0);
        --bs-table-striped-color: #212529;
        --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
        --bs-table-active-color: #212529;
        --bs-table-active-bg: rgba(0, 0, 0, 0.1);
        --bs-table-hover-color: #212529;
        --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
        width: 100%;
        margin-bottom: 1rem;
        color: #212529;
        vertical-align: top;
        border-color: rgba(22, 163, 254, 0.2);
    }

    .table {
        color: inherit;

        tr {
            &:last-of-type {

                td,
                th {
                    border: none;
                    padding-bottom: 0;
                }
            }
        }
    }

    .table-head,
    .table-des {
        padding: 12px;
        background-color: transparent;
        padding-left: 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 0;
    }

    .table-head {
        font-weight: 500;
        color: #c89623;
    }

    .table-des {
        font-weight: 300;
    }
}

.prblm-item,
.sltn-item {
    position: relative;
    border-radius: 8px;
    box-shadow: 0px 5px 20px 0px rgb(0 0 0 / 5%);
    padding: 30px 30px 30px 25px;
}

.prblm-item {
    background: #fff;
    margin-right: 20px;
    padding-bottom: 60px;
}

.sltn-item {
    background: linear-gradient(45deg, #3a416f 0%, #141727 100%);
    z-index: 2;
    margin-left: 20px;
    margin-top: -35px;
}

.prblm-title,
.sltn-title {
    text-transform: uppercase;
    font-weight: 700;
    padding-bottom: 5px;
}

.prblm-title {
    color: rgba(90, 90, 90, .2) !important;
}

.sltn-title {
    color: rgba(255, 249, 250, .3) !important;
}

.sltn-subtitle {
    color: #fff !important;
}

.cbix-wallet-item {
    position: relative;
    padding-left: 0;
    padding-bottom: 15px;
    margin-bottom: 15px !important;
    padding-top: 8px;
    display: inline-block;

    &::before {
        position: absolute;
        width: 40px;
        height: 3px;
        border-radius: 1.5px;
        content: '';
        background: #c89623;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    h5,
    p {
        color: #fff !important;
        margin-bottom: 0 !important;
    }
}

.section-tokendes {
    >.h-70 {
        position: absolute;
        bottom: 0;
        top: auto;
        left: 0;
        right: 0;
        height: 70%;
        z-index: 1;
        display: block;

        &::before,
        &::after {
            position: absolute;
            content: "";
            top: 0;
            right: 0;
            bottom: -1px;
            left: -1px;
            z-index: 1;
        }
    }
}

.table-token {
    margin-bottom: 0 !important;
}

.document-list {
    >li {
        display: inline-block;
        padding: 22px 30px;
        position: relative;
    }
}

.section-pad-md {
    padding-top: 112.5px;
    padding-bottom: 112.5px;
}

// Footer
#back-to-top {
    position: fixed;
    bottom: -40px;
    right: 40px;
    z-index: 1020;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    background: #c89623;
    cursor: pointer;
    border: 0;
    border-radius: 3px;
    text-decoration: none;
    outline: none;
    opacity: 0;
    visibility: hidden;

    &:hover {
        background: #c89623;
    }

    &.show {
        bottom: 80px;
        opacity: 1;
        visibility: visible;
    }

    &::before {
        font-family: "FontAwesome";
        font-size: 14px;
        content: "\f176";
        color: #fff;
        position: relative;
        margin: 5px;
    }
}

#back-to-top {
    -o-transition: .3s;
    -ms-transition: .3s;
    -moz-transition: .3s;
    -webkit-transition: .3s;
    transition: .3s;
    outline: none;
}

.footer-particle {
    background: #15257b url(../images/footer-bg.png) no-repeat 50% 0/contain;
}

.footer-section {
    overflow: hidden;
    background-image: linear-gradient(45deg, #3a416f 0%, #141727 100%);
    padding: 50px 0 50px;

    .copyright-text {
        font-size: 13px;
        display: block;
    }

    .footer-links {
        margin-top: 10px;

        >li {
            display: inline-block;
            position: relative;
            margin: 0 10px;

            a {
                color: #fff;
                font-size: .8em;

                &:hover,
                &:active {
                    color: #c89623 !important;
                }
            }
        }
    }
}

// auth > login
.authincation {
    background: linear-gradient(45deg, #3a416f 0%, #141727 100%);
    min-height: 100vh;

    .mini-logo {
        img {
            max-width: 180px;
        }
    }

    img {
        &.icon {
            opacity: .1;
        }
    }

    .card-title {
        font-size: 1.75rem;
        text-align: center;
        font-weight: 300;
        display: block;
        margin-bottom: .5rem;
    }

    small {
        &.card-subtitle {
            display: block;
            padding-bottom: 10px;
            font-size: 1.1rem;
            margin-top: 0.75rem;
            font-weight: 400;
        }
    }

    .card-header {
        display: block;
        text-align: center;
    }

    .label,
    label {
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0.2em;
    }
}

// add wallet address
.add-token-address-nav {
    border: none;
    margin: 0 0 30px;
    padding: 5px 8px;
    border-radius: 30px;
    display: inline-block;
    background-image: linear-gradient(45deg, #3a416f 0%, #141727 100%);

    .nav-item {
        display: inline-block;
        margin: 0 -3px;

        .nav-link {
            border: none;
            line-height: 24px;
            padding: 8px 18px;
            background: transparent;
            border-radius: 20px;
            font-weight: 500;
            color: #fff;
            font-size: 12px;

            &.active {
                color: #CFA62C;
                background: #fff;
            }

            &:hover {
                cursor: pointer;
            }
        }

        &:first-child {
            .nav-link {
                &:hover {
                    cursor: default;
                    color: #fff !important;
                }
            }
        }
    }
}

// faq
#faq {
    ol {
        padding-left: 15px;

        li {
            list-style: auto;
            margin-bottom: 2rem;
        }
    }

    .add-fantom {
        figure {
            img {
                max-width: 50%;
            }
        }
    }

    .accordion-s2 {
        .card-header {
            h5 {
                margin-bottom: 0;
                display: block;
                width: 100%;
            }
        }
    }
}

.menu-item-has-children {
    position: relative;
    padding-bottom: 10px;

    .sub-menu {
        display: none;
        background-color: #fff;
        border-radius: 3px;
        position: absolute;
        min-width: 220px;
        top: 55px;
        right: 0;
        padding: .5rem 0 .65rem;

        &::after {
            position: absolute;
            top: -7px;
            right: 20px;
            content: "";
            height: 0;
            width: 0;
            border-bottom: 7px solid #fff;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
        }

        li {
            &.menu-item {
                a {
                    font-size: 12px;
                    font-weight: 500;
                    color: #3a416f;
                    padding: 0.25rem 1rem;

                    &:hover,
                    &:focus {
                        color: #CFA62C !important;
                    }
                }
            }
        }
    }

    &:hover {
        .sub-menu {
            display: block;
        }
    }
}

.roadmap {
    .timeline-row {
        &::before {
            top: 41px;
            border-bottom: 3px solid #CFA62C;
        }
    }

    .timeline-row {
        &::after {
            top: 41px;
            width: 15px;
            height: calc(100% + 3px);
            border: 3px solid #CFA62C;
        }
    }

    .timeline-row-even {
        &::after {
            left: -15px;
            border-right: none;
        }
    }

    .timeline-row-odd {
        &::after {
            right: -15px;
            border-left: none;
        }
    }

    .timeline-row-done {
        &::after {
            border-color: #fff;
        }
    }
}