body {
    font-family: 'Roboto', 'Open Sans', muli;
    font-size: 15px;

    .text-primary {
        color: #c89623 !important;
    }

    .toggle-label {
        font-size: 14px;
    }
}

.conversion-input-group {
    input {
        text-align: right;
        border-right: none;
        padding-right: 0;

        &:focus {
            border-color: rgba(55, 55, 89, 0.1);
            outline: none;
        }
    }

    .input-group-text {
        color: rgba($color: #1f2641, $alpha: .5)
    }

    .input-group-prepend {
        .input-group-text {
            border-radius: 0;
            font-size: 16px !important;
            padding-top: 9px;
            padding-bottom: 10px;
            background-color: #f6f8fe;
            border-right: 0px;
        }
    }
    .input-group-append {
        .input-group-text {
            border-radius: 4px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            font-size: 16px !important;
            padding-top: 9px;
            padding-bottom: 10px;
            background-color: #f6f8fe;
            border-left: 0px;
        }
    }
}

.input-group {
    &.custom {
        .input-group-prepend {
            .input-group-text {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                font-size: initial !important;
                padding: 10px 15px 9px;
            }
        }

        &.has-error {
            .input-group-prepend {
                .input-group-text {
                    border-top-color: #ff788e;
                    border-bottom-color: #ff788e;
                }
            }
            select.form-control {
                border-color: #ff788e;
                border-right-color: rgba(55, 55, 89, 0.1);
            }
            input.form-control {
                &:focus {
                    border-color: #ff788e !important;
                }
            }
        }
    }
    .input-group-btn {
        background: transparent;
        border-color: rgba(55,55,89,.1);
        color: #344767;
        min-width: auto;
    }
}

.mb-3 {
    label {
        font-size: .85rem;
        font-weight: 700;
        margin-bottom: 0.5rem;
        color: #344767;
        margin-left: 0.25rem;
    }

    .input-group {
        label {
            margin-left: 0;
        }
    }
}

.toggle-checkbox:checked + .toggle-switch {
    background-color: rgba(58,65,111,.95);
}

input,
select {
    &:focus {
        border-color: rgba(55, 55, 89, 0.1) !important;
        outline: none !important;
    }
}

.otp-code-wrapper {
    margin: auto;
    width: 378px !important;

    > div {
        input {
            border-radius: 4px !important;
            border: 1px solid rgba(55,55,89,.1) !important;
            font-family: inherit;
            margin-right: 5px;
        }
    }
}

.modal-header {
    button {
        &.btn-close {
            min-width: 20px;
        }
    }
}

.link {
    font-weight: 600;

    .fa {
        font-weight: 900;
    }
}

.card-title {
    font-weight: 600;
    font-size: 1.25rem;
}

.modal-title {
    font-weight: 600;
    font-size: 1.15rem;
}

.phone_verified,
.phone_verified {
    .h5,
    h5 {
        font-size: .9rem;

        span {
            color: #3a416f;
        }
    }

    .not-verified {
        display: flex;
    }
}

.profile_log {
    .user {
        .thumb {
            background-color: #3a416f;
        }
        .name {
            font-size: 15px;
            font-weight: 500;
        }
    }

    .dropdown-menu {
        .dropdown-item {
            i {
                display: none;
            }
            
            &:hover {
                background-color: #F5F8FD;

                i {
                    color: inherit;
                    display: none;
                }
            }
        }
    }
}

.alert-light {
    background-color: rgba(55, 55, 89, 0.04);
    border-color: rgba(55, 55, 89, 0.043);
}

// Placeholder
::placeholder {
    color: rgba($color: #8691b4, $alpha: .5) !important;
    opacity: 1; /* Firefox */
}
  
:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: rgba($color: #8691b4, $alpha: .5) !important;
}
  
::-ms-input-placeholder { /* Microsoft Edge */
   color: rgba($color: #8691b4, $alpha: .5) !important;
}

// confirm-box
.confirm-box {
    margin-left: -100px;
    
    .confirm-box__overlay {
        background-color: rgba($color: #fff, $alpha: .95);
    }
}

.toggle-label {
    color: #8691b4;
    font-weight: normal;
}

// stepper
.stepper-container {
    .step {
        min-width: inherit;
    }
}

// button
.btn {
    text-transform: uppercase;
    letter-spacing: -.025rem;
    transition: all .3s ease-in;
    box-shadow: 0 4px 7px -1px rgb(0 0 0 / 11%), 0 2px 4px -1px rgb(0 0 0 / 7%);
    background-size: 150%;
    background-position-x: 25%;
    font-weight: 600;
    -moz-transition: all .3s ease-in;
    -webkit-transition: all .3s ease-in;
    -o-transition: all .3s ease-in;

    &.bg-gradient-dark {
        background-image: linear-gradient(310deg,#141727,#3a416f);
        border: none;
        color: #fff;
    
        &:hover,
        &:focus,
        &:active {
            background-image: linear-gradient(310deg,#3a416f,#141727);
            color: #fff;
        }
    }

    &.bg-gradient-primary {
        background-image: linear-gradient(310deg,#440409,#c89623);
        border: none;
    
        &:hover,
        &:focus,
        &:active {
            background-image: linear-gradient(310deg,#c89623,#440409);
            color: #fff;
        }
    }

    &.btn-outline-secondary {
        color: #8392ab;
        border-color: #8392ab;
        background-color: transparent;
    }
}

// link
a {
    color: #c89623;
    transition: all .3s ease-in;
    -moz-transition: all .3s ease-in;
    -webkit-transition: all .3s ease-in;
    -o-transition: all .3s ease-in;

    &:not(.btn) {
        &:hover,
        &:focus,
        &:active {
            color: #344767 !important;
        }
    }

    &.text-primary,
    .text-primary {
        &:hover,
        &:focus,
        &:active {
            color: #344767 !important;
        }
    }
}

// page title
.page_title {
    p {
        color: #8691b4;
    }
}

// menu nav
.menu {
    ul {
        li {
            a {
                color: #8691b4;

                &.active {
                    i,
                    .nav-text {
                        color: #c89623;
                    }
                }
            }
        }
    }
}

// settings menu nav
.settings_menu {
    ul {
        li {
            a {
                color: #8691b4;

                &.active {
                    color: #344767 !important;

                    span {
                        font-weight: 600;
                    }
                }
            }
        }
    }
}

// nav-pills
.nav-pills,
.nav-pills {
    .nav-link {
        color: #344767 !important;
        font-weight: 700;
        letter-spacing: 0.87px;
        background-color: #f8f8f8;
        font-size: .8rem;

        &.active {
            color: #fff !important;
            background-color: #344767;
        }
    }
    .show {
        > .nav-link {
            color: #fff !important;
            background-color: #344767;
        }
    }
}

// header
.navbar-brand {
    img {
        max-width: 50px;
    }
}

.sidebar {
    .brand-logo {
        padding-top: 0;
        padding-bottom: 0;

        img {
            width: 60px;
            margin-bottom: 0;
        }
    }
}

// confirm alert
.confirm-box {
    left: 0 !important;
    top: 0 !important;
    position: fixed !important;
    width: 100%;
    margin-left: 0;

    .confirm-alert-wrapper {
        margin-top: 17%;
    }

    .confirm-box__overlay {
        height: 100%;
    }
}

// sweet alert (swal2)
.swal2-container {
    .swal2-title {
        font-size: 1.1rem;
    }
}