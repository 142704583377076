.timeline-title {
    color: #2b56f5;
}

.timeline-done .timeline-title {
    color: #223b55;
}

.timeline-item {
    position: relative;
    padding: 0 15px 54px;
}

.timeline-item:before,
.timeline-item:after {
    position: absolute;
    top: 41px;
    width: 50%;
    height: 3px;
    content: "";
    background: #CFA62C;
    opacity: 0;
}

.timeline-item:before {
    left: 0;
}

.timeline-item:after {
    right: 0;
}

.timeline-date {
    text-transform: uppercase;
    font-size: 0.81em;
    font-weight: 700;
    color: #6a7894;
    letter-spacing: 0.1em;
    display: block;
    padding-bottom: 26px;
    position: relative;
    z-index: 2;
}

.timeline-date:after,
.timeline-date:before {
    position: absolute;
    bottom: 0;
    left: 50%;
    border-radius: 50%;
    content: "";
}

.timeline-date:after {
    margin-left: -5px;
    height: 11px;
    width: 11px;
    background: #CFA62C;
}

.timeline-date:before {
    margin-left: -13px;
    margin-bottom: -8px;
    height: 27px;
    width: 27px;
    background: rgba(207, 166, 44, 0.23);
    opacity: 0;
}

.timeline-title {
    color: #2b56f5;
    margin-bottom: 4px;
    padding-top: 48px;
    position: relative;
}

.timeline-title:before {
    position: absolute;
    top: 0;
    left: 50%;
    height: 40px;
    width: 0;
    content: "";
    border-right: 1px dashed rgba(186, 188, 195, 0.3);
}

.timeline-info li {
    position: relative;
    padding: 8px 0;
    color: #707d98;
    line-height: 26px;
}

.timeline-info li:after {
    position: absolute;
    left: 50%;
    bottom: 0;
    height: 1px;
    width: 20px;
    margin-bottom: -0.5px;
    margin-left: -10px;
    content: "";
    background: #79859f;
}

.timeline-info li:last-child:after {
    display: none;
}

.timeline-done:before,
.timeline-done:after {
    opacity: 1;
}

.timeline-done .timeline-title {
    color: #223b55;
}

.timeline-done .timeline-date:before {
    opacity: 1;
}

.timeline-current:before,
.timeline-current:after {
    opacity: 1;
}

.timeline-current:after {
    opacity: 0;
}

.timeline-current .timeline-title {
    color: #223b55;
}

.timeline-current .timeline-date:before {
    opacity: 1;
}

.timeline-row {
    position: relative;
}

.timeline-row:before {
    position: absolute;
    top: 42px;
    left: 0;
    width: 100%;
    height: 0;
    content: "";
    border-bottom: 1px dashed rgba(186, 188, 195, 0.3);
}

.timeline-row:after {
    position: absolute;
    top: 42px;
    width: 15px;
    height: calc(100% + 1px);
    content: "";
    border: 1px dashed rgba(186, 188, 195, 0.3);
}

.timeline-row-done:after {
    position: absolute;
    top: 41px;
    width: 15px;
    height: calc(100% + 3px);
    content: "";
    border: 3px solid #16a1ff;
}

.timeline-row-done.timeline-row-odd:after {
    right: -15px;
}

.timeline-row-done.timeline-row-even:after {
    left: -15px;
}

.timeline-row-odd:after {
    right: -17px;
    border-left: 0;
    border-radius: 0 20px 20px 0;
}

.timeline-row-even:after {
    left: -17px;
    border-right: 0;
    border-radius: 20px 0 0 20px;
}

.timeline-row-even .timeline-item {
    padding-left: 40px;
    padding-right: 40px;
}

.timeline-row-even .timeline-item:before {
    right: 0;
    left: auto;
}

.timeline-row-even .timeline-item:after {
    left: 0;
    right: auto;
}

.timeline-row-last:after {
    display: none;
}

.timeline-row-last .timeline-item {
    padding-bottom: 0;
}

@media only screen and (max-width: 991px) {
    .timeline-item {
        padding: 30px 25px 24px;
    }

    .timeline-item:before,
    .timeline-item:after {
        width: 3px;
    }

    .timeline-item:before {
        top: 0;
        left: -1px;
        height: 72px;
    }

    .timeline-item:after {
        top: 72px;
        right: auto;
        left: -1px;
        height: calc(100% - 72px);
    }

    .timeline-date:after,
    .timeline-date:before {
        left: 0;
    }

    .timeline-date:after {
        margin-left: -30px;
        margin-bottom: -5px;
    }

    .timeline-date:before {
        margin-left: -38px;
        margin-bottom: -13px;
    }

    .timeline-title {
        padding-top: 30px;
    }

    .timeline-title:before {
        top: 0;
        left: -25px;
        height: 0;
        width: 40px;
        margin-top: -1.5px;
        border-right: none;
        border-top: 1px dashed rgba(186, 188, 195, 0.3);
    }

    .timeline-info li:after {
        left: 0;
        margin-left: 0;
    }

    .timeline-row {
        margin-left: 12px;
    }

    .timeline-row:before {
        top: 0;
        width: 0;
        height: 100%;
        border-left: 1px dashed rgba(186, 188, 195, 0.3);
        border-bottom: none;
    }

    .timeline-row:after {
        display: none;
    }

    .timeline-row-even .timeline-item {
        padding-left: 25px;
        padding-right: 25px;
    }

    .timeline-row-even .timeline-item:before {
        right: auto;
        left: -1px;
    }

    .timeline-row-even .timeline-item:after {
        left: -1px;
        right: auto;
    }
}