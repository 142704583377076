.loader-wrapper {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    overflow: hidden;
    background-color: rgba(255,255,255,.99);
    height: 100vh;
    width: 100%;
    text-align: center;

    .loader-container {
        display: inline-block;
        position: absolute;
        top: 50vh;
    }

    .loader {
        margin-top: -24px;
        margin-left: -24px;
    }

    &.card-loader {
        position: absolute;
        height: 100%;
        min-height: 200px;

        .loader-container {
            top: 50%;
        }

        .loader {
            margin-left: -24px;
        }
    }
}

.loader {
    width: 48px;
    height: 48px;
    border: 3px solid #c89623;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;

    &::after {
        content: '';  
        box-sizing: border-box;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 3px solid;
        border-color: #344767 transparent;
    }
}
  
@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}