.form-control {
    border-radius: 6px;
    border: 1px solid #dbe4f7;
    width: 100%;
    padding: 10px 15px;
    line-height: 21px;
    font-size: .9em;
    color: #415076;
    transition: all .4s;
    background-color: rgba(0,0,0,0);
    font-weight: 300;
}

small {
    &.error {
        line-height: 1.4;
        display: block;
        margin-top: 0.3rem;
    }
}