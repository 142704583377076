.intro {
    img {
        &.logo {
            max-width: 100%;
        }
    }
}

.buddy-balance {
    text-align: right;
}