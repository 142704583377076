.kyc-wrapper {
    position: relative;

    ul {
        li {
            list-style: disc;
            margin-left: 1.3rem;
        }
    }

    .kyc-action-buttons {
        transition: ease all .3s;
        margin-bottom: .5rem;

        .btn {
            &.btn-next {
                i {
                    margin-left: .8rem;
                }
            }
            &.btn-back {
                i {
                    margin-right: .8rem;
                }
            }

            &:hover {
                &.btn-next {
                    i {
                        margin-left: 1rem;
                    }
                }
                &.btn-back {
                    i {
                        margin-right: 1rem;
                    }
                }
            }
        }
    }
}

.table {
    &.custom-table {
        thead {
            tr {
                td, th {
                    background-color: transparent;
                    padding: 0 18px;
                }
            }
        }
        tbody {
            tr {
                td {
                    font-weight: normal;
                }
                td, th {
                    background-color: transparent;
                    height: 86px;
                }
            }
        }
        margin-bottom: 0;
    }
}