.text-right {
    text-align: right !important;
}

.text-dotted {
    text-decoration: dotted;
}

.text-underline {
    text-decoration: underline;
}

.h-70 {
    height: 70%;
}

.no-shadow {
    box-shadow: none !important;
}

.unordered-list {
    list-style: square;
    margin-left: 1.5rem;

    li {
        list-style: square;
        display: list-item;
    }
}

select {
    &.form-control {
        background-image:
            linear-gradient(45deg, transparent 50%, gray 50%),
            linear-gradient(135deg, gray 50%, transparent 50%),
            linear-gradient(to right, #ccc, #ccc);
        background-position:
            calc(100% - 20px) calc(1em + 2px),
            calc(100% - 15px) calc(1em + 2px),
            calc(100% - 2.5em) 0.5em;
        background-size:
            5px 5px,
            5px 5px,
            1px 1.5em;
        background-repeat: no-repeat;
        transition: none;

        &:focus,
        &:hover {
            background-image:
                linear-gradient(45deg, gray 50%, transparent 50%),
                linear-gradient(135deg, transparent 50%, gray 50%),
                linear-gradient(to right, #ccc, #ccc);
            background-position:
                calc(100% - 15px) 1em,
                calc(100% - 20px) 1em,
                calc(100% - 2.5em) 0.5em;
            background-size:
                5px 5px,
                5px 5px,
                1px 1.5em;
            background-repeat: no-repeat;
            outline: 0;
        }
    }
}

.deposit-details {
    small {
        font-weight: normal;
        font-size: 0.65em;
        line-height: 1;
    }

    label {
        color: #1f2641;
        font-weight: 500;
    }

    .row {
        margin-bottom: 1.1rem;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.pointer-on-hover {
    &:hover {
        cursor: pointer;
        opacity: .7;
    }
}

.btn {
    &.btn-default {
        display: inline-block;
        margin-left: 10px;
        text-align: center;
        padding: 5px 10px;
        border: 1px solid rgba(55, 55, 89, .1);
        transition: all .3s ease;
        font-weight: 500;
        border-radius: 5px;

        &.active,
        &:focus,
        &:hover {
            background: #5e37ff;
            color: #fff;
            border-right: 1px solid transparent;
        }
    }
}

.btn-check:active+.btn-primary:focus,
.btn-check:checked+.btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none !important;
}

.text-border {

    &::after,
    &::before {
        content: "";
        display: inline-block;
        width: 30%;
        height: 1px;
        position: relative;
        vertical-align: middle;
    }

    &::before {
        background: linear-gradient(90deg, transparent, hsla(0, 0%, 46%, .4), hsla(0, 0%, 46%, .4));
        right: 0.5em;
        margin-left: -50%;
    }

    &::after {
        background: linear-gradient(90deg, hsla(0, 0%, 46%, .4), hsla(0, 0%, 46%, .4), transparent);
        left: 0.5em;
        margin-right: -50%;
    }
}

img {
    &.icon {
        max-width: 100px;

        &.x2 {
            max-width: 150px
        }

        &.x3 {
            max-width: 200px
        }
    }
}

.opacity-1 {
    opacity: .1
}

.opacity-2 {
    opacity: .2
}

.opacity-3 {
    opacity: .3
}

.opacity-4 {
    opacity: .4
}

.opacity-5 {
    opacity: .5
}

.opacity-6 {
    opacity: .6
}

.opacity-7 {
    opacity: .7
}

.opacity-8 {
    opacity: .8
}

.opacity-9 {
    opacity: .9
}

.font-weight-bold {
    font-weight: 600;
}

.font-weight-bolder {
    font-weight: 700;
}

.table {
    &.fees-table {
        border-collapse: collapse;
        border-spacing: 0;

        > :not(:first-child) {
            border-top-width: 1px;
            border-color: rgba(134, 145, 180, 0.2);
        }

        h5 {
            color: #c89623;
            font-weight: 700;
            padding-bottom: 0;
            margin-bottom: 0;
        }

        tr {
            td {
                background-color: transparent;
                font-weight: normal;
                padding: 15px;
            }

            th {
                padding: 15px;
            }
        }
    }
}

.no-records {
    color: #797979;
    text-align: center;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: #797979;
        font-weight: 400;
    }

    .icon {
        min-height: 200px;
        width: 100%;
        text-align: center;
        background-image: url(./images/noresults.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
    }
}

.wide-auto,
.wide-auto-rg,
.wide-auto-xl,
.wide-auto-lg,
.wide-auto-md,
.wide-auto-sm,
.wide-auto-xs {
    margin: 0 auto;
}

.wide-sm,
.wide-auto-sm {
    max-width: 555px;
}

.card-border {
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 18px;

    &.primary {
        border-color: rgba(1200, 150, 35, 0.2);
    }
}

// timeline row

.timeline-row {
    position: relative;

    &::before {
        position: absolute;
        top: 42px;
        left: 0;
        width: 100%;
        height: 0;
        content: "";
        border-bottom: 1px dashed rgba(186, 188, 195, 0.3);
    }

    &::after {
        position: absolute;
        top: 42px;
        width: 15px;
        height: calc(100% + 1px);
        content: "";
        border: 1px dashed rgba(186, 188, 195, 0.3);
    }
}

.timeline-row-done {
    &::after {
        position: absolute;
        top: 41px;
        width: 15px;
        height: calc(100% + 3px);
        content: "";
        border: 3px solid #16a1ff;
    }

    &.timeline-row-odd {
        &::after {
            right: -15px;
        }
    }
    &.timeline-row-even {
        &::after {
            left: -15px;
        }
    }
}

.timeline-row-odd {
    &::after {
        right: -17px;
        border-left: 0;
        border-radius: 0 20px 20px 0;
    }
}

.timeline-row-even {
    &::after {
        left: -17px;
        border-right: 0;
        border-radius: 20px 0 0 20px;
    }
    
    .timeline-item {
        padding-left: 40px;
        padding-right: 40px;

        &::before {
            right: 0;
            left: auto;
        }

        &::after {
            left: 0;
            right: auto;
        }
    }
}

.timeline-row-last {
    &::after {
        display: none;
    }

    .timeline-item {
        padding-bottom: 0;
    }
}