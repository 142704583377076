.dropzone-container {
    position: relative;
    margin: auto;
    text-align: center;

    label {
        border-color: #8691b4;
    }

    .preview {
        position: absolute;
        z-index: 0;
        border-radius: 3px;
        height: calc(100% - 70px);
        top: 25px;
        left: 10px;
        margin: auto;
        width: calc(100% - 20px);
        z-index: 1;
        background-color: white;

        img {
            height: 100%;
        }
    }
    
    .dropzone {
        min-height: 200px;
        display: block;
        text-align: center;
        min-width: 100px;

        svg {
            height: 10rem;
        }
    }
}