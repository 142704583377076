.tkn-crt {
    position: relative;
}

.tkn-crt-img {
    max-width: 205px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
}

.animate .tkn-crt-img {
    opacity: 0;
    position: relative;
    bottom: -30px;
    transition: all 0.4s;
    transition-delay: 0.2s;
}

.animate.show .tkn-crt-img {
    opacity: 1;
    bottom: 0;
}

.tkn-crt-lst {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -20px;
}

.tkn-crt-itm {
    position: static;
    align-items: center;
    width: 50%;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 20px;
}

.animate .tkn-crt-itm {
    opacity: 0;
    transition: all 0.4s;
    transition-delay: 0.6s;
    transform: translateX(0) translateY(30px);
}

.animate.show .tkn-crt-itm {
    opacity: 1;
    transform: translateX(0) translateY(0);
}

.tkn-crt-itm:nth-child(1) {
    transition-delay: 0.7s;
}

.tkn-crt-itm:nth-child(2) {
    transition-delay: 0.8s;
}

.tkn-crt-itm:nth-child(3) {
    transition-delay: 0.9s;
}

.tkn-crt-itm:nth-child(4) {
    transition-delay: 1s;
}

.tkn-crt-itm:nth-child(5) {
    transition-delay: 1.1s;
}

.tkn-crt-itm:nth-child(6) {
    transition-delay: 1.2s;
}

.tkn-crt-itm:nth-child(7) {
    transition-delay: 1.3s;
}

.tkn-crt-prcnt {
    height: 55px;
    width: 55px;
    border-radius: 10px;
    background: #c89623;
    font-size: 16px;
    font-weight: 600;
    line-height: 55px;
    display: inline-block;
    text-align: center;
    color: #fff;
    margin: 20px 30px;
    flex-shrink: 0;
    transform: rotate(-45deg);
}

.tkn-crt-prcnt>span {
    display: inline-block;
    transform: rotate(45deg);
}

.tkn-crt-prcnt.one {
    background: #fe4350;
}

.tkn-crt-prcnt.two {
    background: #fb6b5d;
}

.tkn-crt-prcnt.three {
    background: #ff5676;
}

.tkn-crt-prcnt.four {
    background: #ff774e;
}

.tkn-crt-prcnt.five {
    background: #ff6993;
}

.tkn-crt-prcnt.six {
    background: #ff9465;
}

.tkn-crt-prcnt.seven {
    background: #fb7fc8;
}

.tkn-crt-ttl {
    font-weight: 400;
    text-align: center;
    display: block;
    color: #223b55;
}

@media (min-width: 576px) {
    .tkn-crt-lst {
        margin-bottom: 0;
    }

    .tkn-crt-itm {
        display: flex;
        margin-bottom: 0;
        padding-left: 0;
        padding-right: 0;
    }
}

@media (min-width: 768px) {
    .tkn-crt-itm {
        width: 33%;
    }

    .tkn-crt-ttl {
        text-align: left;
    }
}

@media (min-width: 992px) {
    .tkn-crt {
        position: relative;
        padding-top: 140px;
    }

    .tkn-crt:before {
        position: absolute;
        height: 610px;
        width: 610px;
        top: 0;
        left: 50%;
        content: '';
        border-radius: 20px;
        background: rgba(254, 67, 80, .05);
        transform: rotate(45deg);
        transform-origin: 0 0;
    }

    .tkn-crt-itm {
        position: absolute;
        display: flex;
        align-items: center;
        width: auto;
    }

    .tkn-crt-itm:nth-child(1) {
        flex-direction: column;
    }

    .tkn-crt-itm:nth-child(1) .tkn-crt-ttl {
        order: -1;
    }

    .tkn-crt-itm:nth-child(1) .tkn-crt-prcnt {
        // transform: rotate(0);
    }

    .tkn-crt-itm:nth-child(1) .tkn-crt-prcnt>span {
        // transform: rotate(0);
    }

    .tkn-crt-itm:nth-child(2n) .tkn-crt-ttl {
        order: -1;
    }

    .tkn-crt-itm:nth-child(even) {
        right: 50%;
        transform: translateX(-30px) translateY(0);
    }

    .animate.show .tkn-crt-itm:nth-child(even) {
        transform: translateX(0) translateY(0);
    }

    .tkn-crt-itm:nth-child(odd) {
        left: 50%;
        transform: translateX(30px) translateY(0);
    }

    .animate.show .tkn-crt-itm:nth-child(odd) {
        transform: translateX(0) translateY(0);
    }

    .tkn-crt-itm:nth-child(1) {
        top: -60px;
        left: 50%;
        transform: translateX(-50%) translateY(-30px);
    }

    .animate.show .tkn-crt-itm:nth-child(1) {
        transform: translateX(-50%) translateY(0);
    }

    .tkn-crt-itm:nth-child(2) {
        top: 65px;
        margin-right: 50px;
        transition-delay: 0.8s;
    }

    .tkn-crt-itm:nth-child(3) {
        top: 65px;
        margin-left: 50px;
        transition-delay: 0.8s;
    }

    .tkn-crt-itm:nth-child(4) {
        top: 173px;
        margin-right: 160px;
        transition-delay: 1s;
    }

    .tkn-crt-itm:nth-child(5) {
        top: 173px;
        margin-left: 160px;
        transition-delay: 1s;
    }

    .tkn-crt-itm:nth-child(6) {
        top: 281px;
        margin-right: 265px;
        transition-delay: 1.2s;
    }

    .tkn-crt-itm:nth-child(7) {
        top: 281px;
        margin-left: 265px;
        transition-delay: 1.2s;
    }

    .tkn-crt-ttl {
        white-space: nowrap;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .tkn-crt {
        transform: scale(0.8);
    }
}