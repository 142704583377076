.change-mobile {
    .mb-3 {
        .input-group {
            label {
                &.phone-code {
                    padding: 10px 15px 9px;
                    font-weight: bold;
                    font-size: initial !important;
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .linked-accounts {
        .nav {
            &.nav-pills {
                .nav-item {
                    width: 100%;
                    text-align: center;

                    &:first-of-type {
                        margin-bottom: .5rem !important;
                    }

                    &:last-of-type {
                        padding-left: 0 !important;
                        margin-bottom: 2rem !important;
                    }
                }
            }
        }

        .form {
            > div {
                text-align: center !important;
            }
        }
    }
}
