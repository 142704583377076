.txn-details-wrapper {
    .data-details {
        border-radius: 4px;
        padding: 18px 20px;
        border: 1px solid #d2dde9;
    
        > div {
            flex-grow: 1;
            margin-bottom: 18px;
        }
    }
    
    .data-details-title {
        font-size: 14px;
        font-weight: 600;
        color: #758698;
        line-height: 20px;
        display: block;
    }
    
    .data-details-info {
        font-size: 14px;
        font-weight: 400;
        color: #495463;
        line-height: 20px;
        display: block;
        margin-top: 6px;
    }
    
    .badge {
        padding: 2px 10px;
        font-size: 11px;
        line-height: 15px;
        text-align: center;
        border-radius: 3px;
        font-weight: 500;
        color: #fff;
        border: 1px solid;
        min-width: 60px;
        display: inline-block;
        white-space: nowrap;
        vertical-align: baseline;
    }
    
    .badge-success {
        background: #00d285;
        border-color: #00d285;
    }

    .badge-warning {
        background: #ffc100;
        border-color: #ffc100;
    }

    .badge-danger {
        background: #ff6868;
        border-color: #ff6868;
    }

    .badge-primary {
        background: #2c80ff;
        border-color: #2c80ff;
    }

    .badge-light {
        background: #758698;
        border-color: #758698;
        color: #fff;
    }

    .data-details-list {
        border-radius: 4px;
        border: 1px solid #d2dde9;

        li {
            display: flex;
            align-items: center;

            &:last-of-type {
                .data-details-des {
                    border-bottom: none;
                }
            }
        }
    }

    .data-details-head {
        font-size: 13px;
        font-weight: 500;
        color: #758698;
        line-height: 20px;
        width: 190px;
        padding: 14px 20px;
    }

    .data-details-des {
        font-size: 14px;
        color: #495463;
        font-weight: 400;
        line-height: 20px;
        flex-grow: 1;
        border-bottom: 1px solid #d2dde9;
        display: flex;
        justify-content: space-between;
        border-top: none;
        border-left: 1px solid #d2dde9;
        width: calc(100% - 190px);
        padding: 14px 20px;

        strong {
            color: #495463;
        }
    }
}