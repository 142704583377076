@media only screen and (min-width: 480px) {
    #ico-page-wrapper {
        .btn {
            &.btn-icon {
                padding-right: 65px;
            }
        }

        .token-countdown {
            margin: 0;

            .col {
                padding: 0 11px;

                &:first-of-type {
                    padding-left: 0;
                }
            }
        }
    }

    .presale-stage {
        padding: 30px 30px 18px 60px;
    }

    .presale-status {
        padding: 30px 60px;
    }

    .prblm-item,
    .sltn-item {
        padding: 40px 90px 50px 35px;
    }

    .prblm-item {
        margin-right: 45px;
        padding-bottom: 90px;
    }

    .sltn-item {
        margin-left: 45px;
        margin-top: -45px;
    }
}

@media only screen and (min-width: 576px) {
    #ico-page-wrapper {
        p {
            font-size: 1em;

            &.lead {
                font-size: 1em;
            }
        }

        .table-token {
            display: table;
        }
    }

    .add-token-address-nav {
        border-radius: 30px;

        .nav-item {
            display: inline-block;

            .nav-link {
                padding: 13px 25px;
                border-radius: 25px;
                font-weight: 600;
                font-size: 14px;
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    #ico-page-wrapper {
        .banner {
            min-height: 92vh;
            height: auto;

            .banner-content {
                padding-bottom: 120px;
            }
        }

        .presale-stage {
            border-radius: 6px 0 0 6px;
        }
    }

    .presale-status {
        border-radius: 0 6px 6px 0;
        padding-bottom: 51px;
    }

    .ui-shape {

        &::after {
            width: 120px;
        }

        &.ui-shape-4 {
            &::after {
                right: 2%;
            }
        }
    }

    .prblm-item {
        margin-right: -45px;
        margin-top: 60px;
        padding-bottom: 50px;
    }

    .sltn-item {
        margin-left: -45px;
        margin-bottom: 60px;
        margin-top: 0;
    }
}

@media only screen and (min-width: 992px) {
    #ico-page-wrapper {
        .page-header {
            width: 960px;
            overflow: visible;
        }

        .menu-top {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
        }
    }

    .ui-shape {
        width: 960px;
        overflow: visible;

        &::after {
            width: 166px;
        }

        &.ui-shape-4 {
            &::after {
                right: -7%;
            }
        }
    }

    .prblm-item,
    .sltn-item {
        padding: 40px 135px 50px 50px;
    }

    .prblm-item {
        margin-right: -95px;
        margin-top: 80px;
    }

    .sltn-item {
        margin-left: -95px;
        margin-bottom: 80px;
    }

    .prblm-title,
    .sltn-title {
        padding-bottom: 15px;
    }

    .cbix-wallet-item {
        padding-left: 25px;
        padding-bottom: 4px;
        padding-top: 0;

        &::before {
            width: 3px;
            height: 100%;
            top: 0;
            left: 0;
            transform: translateX(0);
        }
    }

    .wide-auto-md {
        max-width: 950px;
    }

    #token-details {
        .card-border {
            padding: 50px 40px;
        }
    }
}

@media only screen and (min-width: 1200px) {
    #ico-page-wrapper {
        .navbar {
            .navbar-nav {
                >li {
                    >a {
                        &.nav-link.btn {
                            padding: 0 30px;
                            line-height: 42px;
                        }
                    }
                }
            }
        }

        .site-header {
            .navbar-full {
                padding-left: 59px;
                padding-right: 59px;
            }

            .navbar-btns {
                margin: 0 -5px;
            }
        }

        .navbar-btns {
            li {
                margin: 0 5px;
            }
        }

        .page-header {
            width: 1170px;
        }
    }

    .ui-shape {
        width: 1170px;
    }
}

@media only screen and (max-width: 991px) {
    #ico-page-wrapper {

        .section-pad,
        .section-pad-lg {
            padding-top: 90px;
            padding-bottom: 90px;
        }

        .banner {
            &.banner-full {
                min-height: 830px;
            }
        }
    }

    .ico-contract {
        text-align: center;
    }

    .site-header {
        &:not(.has-fixed) {
            .navbar {
                &.is-transparent {
                    &.enable {
                        background: linear-gradient(135deg, #3a416f 0%, #141727 100%) !important;
                    }
                }
            }
        }

        .navbar-btns {
            margin-bottom: 25px;
            padding-top: 10px;
            display: inline-block;
            margin-left: 5px !important;

            li {
                display: inline-block;


                &:hover {
                    opacity: 1;
                }

                &:last-child {
                    margin-left: 10px;
                }

                &.wallet-balance {
                    display: block !important;
                    margin-bottom: .75rem;

                    >span {
                        >span {
                            display: inline-block !important;
                        }
                    }
                }
            }
        }
    }

    .menu-item-has-children {
        .sub-menu {
            position: relative;
            top: 8px;

            &::after {
                left: 20px;
                right: auto;
            }

            li {
                &.menu-item {
                    display: block;
                }

                &:last-child {
                    margin-left: 0;
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    #ico-page-wrapper {

        .section-pad,
        .section-pad-lg {
            padding-top: 60px;
            padding-bottom: 60px;
        }

        .banner {
            min-height: auto;
            padding-bottom: 155px;
        }

        h1,
        .h1 {
            font-size: 2.1em;
        }

        .sltn-coontent,
        .ico-contract {
            .row>* {
                text-align: center;
            }
        }

        #white-paper {
            text-align: center;

            .document-list {
                >li {
                    padding: 0;
                    margin-bottom: 30px;

                    a {
                        min-width: 250px;
                    }
                }
            }
        }
    }

    .ico-transaction-wrapper {
        .token-information {
            display: none;
        }
    }

    .presale-numbers {
        font-size: .8rem;
    }

    .footer-section {
        .copyright-text {
            text-align: center;
        }

        .mobile-left {
            text-align: center !important;
            margin-top: 1rem;
        }
    }

    .tkn-crt-ttl {
        text-align: left;
    }

    // faq
    #faq {
        .add-fantom {
            figure {
                img {
                    max-width: 100%;
                }
            }
        }
    }
}

@media only screen and (max-width: 599px) {
    #ico-page-wrapper {
        .banner {
            &.banner-full {
                min-height: 900px;
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    #ico-page-wrapper {
        .banner {
            &.banner-full {
                min-height: 665px;
            }
        }

        h1,
        .h1 {
            font-size: 1.5em;
        }

        .sltn-item {
            text-align: center;
            padding: 40px 35px 50px 35px;
        }
    }

    .tkn-crt-lst,
    .tkn-crt-ttl {
        text-align: center;
    }

    .add-token-address-nav {
        margin-bottom: 0;
        border-radius: 8px;

        .nav-item {
            &:last-child {
                margin-bottom: 1rem;
                margin-top: .3rem;
                min-width: 280px;
            }
        }
    }
}

@media only screen and (max-width: 427px) {
    #ico-page-wrapper {
        .banner-content {
            .btn.btn-icon {
                min-width: 200px;
            }
        }
    }
}