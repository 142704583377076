.transaction-history {
    color: #8691b4;

    .rdt_TableHead {
        height: 0px;
        
        .rdt_TableHeadRow {
            border: none !important;
        }
    }

    .rdt_TableRow {
        background-color: #f6f8fe;
        border: none !important;
        margin-bottom: 1rem;

        .rdt_TableCell {
            padding: 18px;

            span {
                color: #8691b4;
                font-size: 14px;
                font-weight: 600;
                line-height: 1.5rem;

                &.badge,
                &.buy-thumb,
                &.sold-thumb {
                    color: white;
                    &.bg-success {
                        color: white;
                    }
                }

                &.badge {
                    font-size: 11px;
                    font-weight: 700;
                    padding: 0.35em 0.65em;
                    display: inline-block;
                }
            }
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .rdt_Pagination {
        button {
            min-width: auto;
        }
    }
}

@media only screen and (max-width: 767px) {
    .transaction-history {
        .card {
            .card-header {
                display: block;

                .card-title {
                    margin-bottom: 1rem;
                }

                .btn-group {
                    &.btn-group-lg {
                        > .btn {
                            font-size: 1.1rem;
                            padding: .4rem .5rem;
                            min-width: 100px;
                        }
                    }
                }
            }
        }

        .rdt_TableRow {
            max-width: 100%;
        }

        .rdt_TableCell {
            min-width: min-content;
            max-width: fit-content;

            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(6) {
                display: none;
            }

            // &:nth-of-type(5) {
            //     max-width: 180px;
            // }

            &:nth-of-type(7),
            &:nth-of-type(8) {
                max-width: 70px;
            }
        }

        .transaction-table {
            > div {
                > div {
                    overflow: hidden;
                }
            }
        }
    }
}