table {
    &.table {
        thead {
            tr {
                th {
                    background-color: transparent;
                    padding-bottom: 0;
                    text-transform: uppercase;
                    font-weight: 500;
                    font-size: .8rem;
                }
            }
        }
    }
}